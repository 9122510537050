<template>
  <div class="deviceBill">
    <div class="pageHeader">
      <div>信息反馈</div>
      <div class="pageHeaderBtns">
        <a-button @click="backTo">返回</a-button>
      </div>
    </div>
    <div class="pageContent">
      <div class="pageContentTitle">{{ pageData.receiveTitle }}</div>
      <div class="pageContentHead">
        <span>{{ pageData.receiveSchool }}</span>
        <span class="newsPerson">{{ pageData.receiveUserName }}</span>
        <span class="newsTime" v-if="pageData.createTimeStr"
          >反馈时间：{{ pageData.createTimeStr }}</span
        >
      </div>
      <div class="pageContentInfo">
        <div v-html="pageData.receiveContent"></div>
      </div>
      <div class="pageContentFile" v-if="defaultFileList.length > 0">
        <span>附件：</span>
        <a-upload
          :default-file-list="defaultFileList"
          :remove="false"
          :showUploadList="fileOption"
          @preview="handlePreview"
        >
        </a-upload>
      </div>
    </div>
  </div>
</template>

<script>
import { getBackByMsgCode } from "@/api/newsManage";
export default {
  name: "newsInfo",
  data() {
    return {
      defaultFileList: [],
      fileOption: {
        showPreviewIcon: true,
        showRemoveIcon: false,
      },
      pageData: {
        receiveTitle: "",
        receiveSchool: "",
        receiveUserName: "",
        createTimeStr: "",
        receiveContent: "",
      },
    };
  },
  components: {},
  created() {
    this.getInfo();
  },
  methods: {
    handlePreview(file) {
      if (file.name.indexOf(".zip") > -1 || file.name.indexOf(".rar") > -1) {
        return window.open(file.url);
      } else {
        let Base64 = require("js-base64").Base64;
        return window.open(
          "https://smart-futuresc-kkfileview.ictsoft.cn/onlinePreview?url=" +
            encodeURIComponent(Base64.encode(file.url))
        );
      }
    },
    backTo() {
      this.$router.go(-1);
    },
    getInfo() {
      let data = {
        receiveCode: this.$route.query.code,
      };
      getBackByMsgCode(data).then((res) => {
        this.pageData = res.data.data;
        this.defaultFileList = res.data.data.fileUrls
          ? res.data.data.fileUrls.map((item, index) => {
              return {
                uid: index + 1,
                name: res.data.data.fileNames[index],
                status: "done",
                url: item,
              };
            })
          : [];
      });
    },
  },
};
</script>

<style lang="less" scoped>
.searchIpt {
  width: 200px;
}
.deviceBill {
  width: 100%;
  height: 100%;
}
.pageHeader {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  color: rgba(16, 16, 16, 100);
  background: white;
  text-indent: 27px;
  justify-content: space-between;
  span {
    font-weight: bold;
    margin-right: 10px;
  }
  .pageHeaderBtns {
    margin-right: 27px;
    .leftBtn {
      margin-right: 10px;
    }
  }
}
.pageContent {
  margin-top: 20px;
  background: white;
  padding: 27px;
  .pageContentTitle {
    color: rgba(16, 16, 16, 100);
    font-size: 24px;
    font-family: SourceHanSansSC-bold;
    margin-bottom: 20px;
  }
  .pageContentHead {
    padding-bottom: 14px;
    border-bottom: 1px solid #bbbbbb;
    display: flex;
    align-items: center;
    .newsPerson {
      margin-left: 10px;
    }
    .newsTime {
      margin-left: 10px;
    }
  }
  .pageContentInfo {
    margin-top: 20px;
  }
  .pageContentFile {
    margin-top: 20px;
  }
}
</style>
